const ENV_CONFIG = require("./envConfig");
const CommonLibs = require("@tpd-web-common-libs/nodejs-library");
var fs = require('fs');
const oldArkPagesMock = require("./oldArkPagesMock");

// TODO - ADD environment subdomain if missing
const DEPLOY_ENV = {
  PROD: "prod",
  QA: "qa",
  SIT: "sit",
  HOTFIX: "fix",
  DEV: "dev",
};
// TODO - CAMBIARE ENV_TO_DEPLOY COERENTEMENTE AL DEPLOY NEI VARI AMBIENTI
const ENV_TO_DEPLOY = DEPLOY_ENV.PROD;

function getOldArkEndpoint(pagePath) {
  if (ENV_TO_DEPLOY === DEPLOY_ENV.PROD) {
    // TODO: rebranding 2025 modificare per rilascio di prod
    return `https://www.unipol.it${CommonLibs.Helpers.EndpointHelper.completePath(
      pagePath
    )}`;
  } else if (ENV_TO_DEPLOY === DEPLOY_ENV.QA) {
    return `https://qa.unipol.it${CommonLibs.Helpers.EndpointHelper.completePath(
      pagePath
    )}`;
  } else if (ENV_TO_DEPLOY === DEPLOY_ENV.HOTFIX) {
    // TODO: rebranding 2025 modificare per rilascio di hotfix
    return `https://fix.unipolsai.it${CommonLibs.Helpers.EndpointHelper.completePath(
      pagePath
    )}`;
  } else {
     // TODO: rebranding 2025 modificare per rilascio a seconda dell'ambiente
    return CommonLibs.Helpers.EndpointHelper.getHostEndpoint(pagePath)
      .replace("https://dev.unipol.it", "https://svil.unipolsai.it")
      .replace("https://evo-dev.unipolsai.it", "https://svil.unipolsai.it")
      .replace("https://sit.unipol.it", "https://inte.unipolsai.it")
      .replace("https://evo-sit.unipolsai.it", "https://inte.unipolsai.it")
      .replace("https://qa.unipol.it", "https://coll.unipolsai.it")
      .replace("https://fix.unipolsai.it", "https://axprtpdren.servizi.gr-u.it")
      .replace("https://www.unipol.it", "https://axprtpdren.servizi.gr-u.it")
      .replace("https://unipol.it", "https://axprtpdren.servizi.gr-u.it");
  }
}

async function getPagesTemp(fetchingFromCMSProxy = false) {
  if(fetchingFromCMSProxy) {
    console.log('[DeployManagemet] getPagesTemp is fetchingFromCMSProxy');
    const cmsProxyService = CommonLibs.Providers.CMSProxyService.getInstance();
    return await cmsProxyService.getPages();
  } else {
    console.log('[DeployManagemet] getPagesTemp is NOT fetchingFromCMSProxy');
    return oldArkPagesMock;
  }
}

/**
 * Executes a copy of .proxy-tpd folder under Next public folder, according to: ENV_TO_DEPLOY
 */
function enrichNextPublicAssets() {
  if (
    ENV_CONFIG.ENV_NAME != "local_development" &&
    ENV_CONFIG.ENV_NAME != "local_production"
  ) {
    let envToDeploy = ENV_TO_DEPLOY;
    if(ENV_TO_DEPLOY === DEPLOY_ENV.HOTFIX) {
      envToDeploy = DEPLOY_ENV.PROD
    } else if (ENV_TO_DEPLOY === DEPLOY_ENV.SIT) {
      envToDeploy = DEPLOY_ENV.QA
    }
    console.error("enrichNextPublicAssets IN PROGRESS");
    fs.cp(`./.proxy-tpd/${envToDeploy}`, './public', { recursive: true }, (err) => {
      if (err) {
        console.error("enrichNextPublicAssets FAILED", err);
      }
    });
  }
}

function patchNextBatcher() {
  console.error("patchNextBatcher IN PROGRESS");
  try {
    // Copia del file con la modalità specificata
    fs.copyFileSync(`batcher.js`, './node_modules/next/dist/lib/batcher.js');
    console.log('File copiato con successo');
  } catch (err) {
      console.error('Errore durante la copia del file:', err);
  }
}


function getEnv() {
  return ENV_TO_DEPLOY;
}

module.exports = {
  getOldArkEndpoint,
  getPagesTemp,
  enrichNextPublicAssets,
  patchNextBatcher,
  getEnv,
  ENV_TO_DEPLOY,
  DEPLOY_ENV,
};
