import { ReactMFE, Providers } from "@tpd-web-common-libs/nodejs-library";
import React from "react";
import { editorSelector } from "../../store/slices/editor.slice";

export const commonHook = ({ useSelector }, isClientRendering: boolean) => {
  const importMFEBy = isClientRendering
    ? (__mfeName__: string) =>
        Providers.RemoteService.getInstance().dynamicImportClientSideMFE(
          __mfeName__
        )
    : (__mfeName__: string) =>
        Providers.RemoteService.getInstance().dynamicImportServerSideMFE(
          __mfeName__
        );

  return (props): ReactMFE.Types.TRemoteReactMFEProps => {
    const { isEditMode, touchpoint } = useSelector(editorSelector);

    return {
      ...props,
      __host__: {
        ...(props.__host__ || {}),
        router: Providers.RouterService.getInstance().router,
        importMFEBy,
        isClientRendering,
      },
      __deps__: React,
      isEditMode,
      touchpoint,
    };
  };
};
