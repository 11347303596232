import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "./store";

const getStoreDependenciesBy = (isClientSideImport) => {
  return isClientSideImport
    ? {
        useDispatch,
        useSelector,
        useState,
      }
    : {
        useDispatch: () => getStore().dispatch,
        useSelector: (selector) => selector(getStore().getState()),
        useState: (defaultValue) => [
          defaultValue,
          () => {
            // do nothing.
          },
        ],
      };
};

export const StoreUtils = {
  getStoreDependenciesBy,
};
