module.exports = [
  {
    "id": "gxsywmg-mnpsrzlgbzdtehtxdtedqznktyeu",
    "url": "/tpdweb/area_riservata/agenzia/iniziative/iniziative-in-corso",
    "title": "Iniziative in corso",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-amibxesrsfxsyuggcvaemdvkcxce",
    "url": "/tpdweb/landing-adv/configuratore-pet-form-adv",
    "title": "Configuratore-pet-form-adv",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-ibkobrlnuwoteesohdrukjyilzxe",
    "url": "/tpdweb/landing-adv/configuratore-pet-adv",
    "title": "C@ne&G@tto",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-xthxtqpaognuyhciufazdtbehezh",
    "url": "/tpdweb/preventivatore-multisettore",
    "title": "Preventivatore Multisettore",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-fqbuwbntmpvtphoeelmzdavnrneu",
    "url": "/tpdweb/preventivatore_moto_app",
    "title": "Preventivatore Moto App",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehhhdawiynquvkiu",
    "url": "/tpdweb/area_riservata/agenzia/convenzione",
    "title": "Dettaglio Convenzione",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-visjyyjsqfbgwhhstajbjizfowph",
    "url": "/tpdweb/aziende/landing-adv/configuratore-infortuni-adv",
    "title": "Infortuni_copia",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "true",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-23"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehgrjbhkevprpfxe",
    "url": "/tpdweb/area_riservata/agenzia/convenzioni/convenzioni-locali",
    "title": "Convenzioni Locali",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-zqybzuvtrjgtehgjaclgiqcoqzte",
    "url": "/tpdweb/preventivatore-moto",
    "title": "Assicurazione Moto",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-08-30"
  },
  {
    "id": "gxsywmg-fwcpwqyvylaifehjftsqrvyzccbh",
    "url": "/tpdweb/configuratore-casa/configuratore-casa-form-app",
    "title": "Configuratore-casa-form-app",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2022-08-01"
  },
  {
    "id": "gxsywmg-punagqyeljknxnrgqdaewrpjyfme",
    "url": "/tpdweb/landing-adv/configuratore-salute-adv",
    "title": "Salute",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehstswqatqnbennu",
    "url": "/tpdweb/area_riservata/agenzia/iniziativa",
    "title": "Dettaglio Iniziativa",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-xjtplpxhecybquscrevygwhrafnu",
    "url": "/tpdweb/configuratore-casa",
    "title": "Configuratore Casa",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-05-15"
  },
  {
    "id": "gxsywmg-xfomaxwjgxzpeegjpdcigcaeghse",
    "url": "/tpdweb/preventivatore-auto/step2-3-prev-auto/riepilogo-prev-auto",
    "title": "Riep_Prev_Auto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-01-18"
  },
  {
    "id": "gxsywmg-knnkvwtykutvdngwuxcqufockknh",
    "url": "/tpdweb/configuratore-infortuni/infortuni-step-2-3",
    "title": "Scelta offerta",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2022-12-13"
  },
  {
    "id": "gxsywmg-aizhxuapypssdncplnyquvyfttju",
    "url": "/tpdweb/assicurazione-moto-fmi",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-xjtplpxhecybquhsjxfegamacuen",
    "url": "/tpdweb/configuratore-casa/configuratore-casa-2-3-step",
    "title": "Configuratore CASA 2-3 step",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2019-10-15"
  },
  {
    "id": "gxsywmg-aqyrlfuvxrkbjnljdvqovalxodwe",
    "url": "/tpdweb/configuratore-viaggi/configuratore-viaggi-acquisto",
    "title": "Configuratore Viaggi Acquisto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2021-12-20"
  },
  {
    "id": "gxsywmg-xfnmacotkqyvlndyukzcqdhhbjhe",
    "url": "/tpdweb/area_riservata/polizze/trattamento-dati-personali",
    "title": "Trattamento dati personali",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-xjtplpxhecybqujfxlbgnonwmpze",
    "url": "/tpdweb/configuratore-pet/pet-step-2-3",
    "title": "Configuratore PET Step 2-3",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2019-10-15"
  },
  {
    "id": "gxsywmg-knnkvwtykutvdnrsrxqshydxalsh",
    "url": "/tpdweb/configuratore-infortuni",
    "title": "Configuratore infortuni",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2020-10-27"
  },
  {
    "id": "gxsywmg-xjtplpxhecybquslvxeqrppjhylu",
    "url": "/tpdweb/configuratore-pet",
    "title": "Configuratore PET",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-05-08"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehsnloeethjsmdpu",
    "url": "/tpdweb/area_riservata/agenzia/ag",
    "title": "Ag",
    "description": "ag",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-aqzdowsfptecwnjzcbxnfoetzptu",
    "url": "/tpdweb/configuratore-salute/salute-step-2-3/configuratore-salute-riepilogo",
    "title": "Riepilogo",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2020-10-27"
  },
  {
    "id": "gxsywmg-vkoggfisckdlnnsrkvqcnexliznh",
    "url": "/tpdweb/landing-adv/assicurazione-auto-adv",
    "title": "KM&Servizi",
    "description": "",
    "layout": "layout-templates/AssicurazioneAutoAdv/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-xjtplpxhecybqujvrbuboyniwjun",
    "url": "/tpdweb/configuratore-casa/configuratore-casa-2-3-step/configuratore-casa-riepilogo",
    "title": "Configuratore CASA Riepilogo",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2019-10-15"
  },
  {
    "id": "gxsywmg-zcthnbkeynrxiuhoimsomuccomah",
    "url": "/tpdweb/configuratore-pet/configuratore-pet-acquisto",
    "title": "Configuratore Pet Acquisto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2021-12-20"
  },
  {
    "id": "gxsywmg-uznkqhktehphpurcesybcyyjadbu",
    "url": "/tpdweb/configuratore-casa/configuratore-casa-form",
    "title": "Configuratore-casa-form",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2023-05-15"
  },
  {
    "id": "gxsywmg-aqzdowsfptecwnnvzevyrbqbmqpe",
    "url": "/tpdweb/configuratore-salute/salute-step-2-3",
    "title": "Scelta offerta",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2020-10-27"
  },
  {
    "id": "gxsywmg-xjtplpxhecybqutbsctgvbstnlte",
    "url": "/tpdweb/configuratore-pet/pet-step-2-3/configuratore-pet-riepilogo",
    "title": "Configuratore PET Riepilogo",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2019-10-15"
  },
  {
    "id": "gxsywmg-xttokovxszhcferwiykcvkotctan",
    "url": "/tpdweb/configuratore-pet/configuratore-pet-form",
    "title": "Configuratore Pet Form Vi",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2023-05-08"
  },
  {
    "id": "gxsywmg-acjaoubzbmngfesoqgmfcvxmowte",
    "url": "/tpdweb/configuratore-viaggi/configuratore-viaggi-form",
    "title": "Configuratore Viaggi Form Vi",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2023-05-08"
  },
  {
    "id": "gxsywmg-visjyyjsqfbgwhgqcqxlgmvrvsbu",
    "url": "/tpdweb/aziende/landing-adv/configuratore-commercio-adv",
    "title": "Commercio&Servizi_copia",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "true",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-23"
  },
  {
    "id": "gxsywmg-fwcpwqyvylaiferjmaxgqzlvblbe",
    "url": "/tpdweb/landing-adv/configuratore-casa-form-adv",
    "title": "Configuratore-casa-form-adv",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-xfomaxwjgxzpeeoirbwtbdniehwu",
    "url": "/tpdweb/preventivatore-auto",
    "title": "Assicurazione Auto",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2022-12-13"
  },
  {
    "id": "gxsywmg-amibxesrsfxsyucxktflizwgkrae",
    "url": "/tpdweb/configuratore-viaggi/configuratore-viaggi-form-app",
    "title": "Configuratore Viaggi Form Vi App",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2022-11-29"
  },
  {
    "id": "gxsywmg-amibxesrsfxsyuwphxkamwaubysn",
    "url": "/tpdweb/landing-adv/configuratore-viaggi-form-adv",
    "title": "Configuratore-viaggi-form-adv",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-zcthnbkeynrxiuqsvruwnvkpzgve",
    "url": "/tpdweb/configuratore-pet/configuratore-pet-preventivazione",
    "title": "Configuratore Pet Preventivazione",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2021-12-20"
  },
  {
    "id": "gxsywmg-bkwluhncowtydndexpirfqzcfpoe",
    "url": "/tpdweb/faicanestroevinci",
    "title": "Fai canestro e vinci",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-krphiqehlkodiuciptxfzhgaqwjh",
    "url": "/tpdweb/area_riservata/no-assistenza",
    "title": "Assistenza base",
    "description": "",
    "layout": "layout-templates/1Column/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2020-01-28"
  },
  {
    "id": "gxsywmg-zacycpipupdttuqqbzpqktoilfve",
    "url": "/tpdweb/preventivatore-eventi",
    "title": "Preventivatore eventi",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-visjyyjsqfbgwhhhjtfmnycldbte",
    "url": "/tpdweb/aziende/landing-adv/configuratore-impresa-adv",
    "title": "Configuratore impresa ADV_copia",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "true",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-23"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehoihaxixlqtrmkh",
    "url": "/tpdweb/area_riservata/agenzia/convenzioni/convenzioni-nazionali",
    "title": "Convenzioni Nazionali",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-30"
  },
  {
    "id": "gxsywmg-fqbuwbntmpvtphopuifpusdwkxae",
    "url": "/tpdweb/preventivatore_auto_app",
    "title": "Preventivatore Auto App",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-zcthnbkeynrxiuopfuoiprcsoqhn",
    "url": "/tpdweb/configuratore-viaggi",
    "title": "Configuratore Viaggi",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-05-08"
  },
  {
    "id": "gxsywmg-aqzdowsfptecwncaowhipfpqrwoe",
    "url": "/tpdweb/configuratore-salute",
    "title": "Configuratore salute",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2021-07-28"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehgdtcbsrgwkcxvh",
    "url": "/tpdweb/area_riservata/agenzia/iniziative/iniziative-passate",
    "title": "Iniziative passate",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-zqybzuvtrjgtehczlfcywjwqcyhh",
    "url": "/tpdweb/preventivatore-moto/step2-3-prev-moto",
    "title": "Step2-3 Prev Moto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-08-30"
  },
  {
    "id": "gxsywmg-zqybzuvtrjgtehrofsfzlnuwrqwe",
    "url": "/tpdweb/preventivatore-moto/step2-3-prev-moto/riepilogo-prev-moto",
    "title": "Riep_Prev_Moto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-08-30"
  },
  {
    "id": "gxsywmg-xfomaxwjgxzpeeghgwamqyntctuu",
    "url": "/tpdweb/preventivatore-auto/step2-3-prev-auto",
    "title": "Step2-3 Prev Auto",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-01-18"
  },
  {
    "id": "gxsywmg-ibkobrlnuwoteewfxrwsryftdxbh",
    "url": "/tpdweb/landing-adv/configuratore-casa-adv",
    "title": "Casa&Servizi",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-xtrkiiisacirbnqszdweicgmioyn",
    "url": "/tpdweb/preventivatori-noauto",
    "title": "calcola-preventivo",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-05-07"
  },
  {
    "id": "gxsywmg-vkoggfisckdlnndujpnoonloxcrn",
    "url": "/tpdweb/landing-adv/assicurazione-moto-adv",
    "title": "KM&Servizi 2Ruote",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-knnkvwtykutvdntklnlvbuhydlzu",
    "url": "/tpdweb/configuratore-infortuni/infortuni-step-2-3/riepilogo",
    "title": "Riepilogo",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2022-12-13"
  },
  {
    "id": "gxsywmg-zfpwqnmontqseedvabeclntplnnh",
    "url": "/tpdweb/area_riservata/polizze/termini-condizioni-pagamento",
    "title": "Termini e condizioni di pagamento",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-uwxcqdjtoawjruopansplulxjdjh",
    "url": "/tpdweb/landing-adv/vodafone",
    "title": "vodafone",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-mnpsrzlgbzdtehgqwcqbsuavzptu",
    "url": "/tpdweb/area_riservata/agenzia/convenzione-nazionale",
    "title": "Convenzione Nazionale",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2023-10-31"
  },
  {
    "id": "gxsywmg-aqyrlfuvxrkbjnhdcasfsrcqtqrh",
    "url": "/tpdweb/configuratore-viaggi/configuratore-viaggi-preventivazione",
    "title": "Configuratore Viaggi Preventivazione",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2021-12-20"
  },
  {
    "id": "gxsywmg-zbyqyzardgqtyutexhpgwaebcbqh",
    "url": "/tpdweb/landing-adv/configuratore-infortuni-adv",
    "title": "Infortuni",
    "description": "",
    "layout": "layout-templates/quotatoriForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-06-04"
  },
  {
    "id": "gxsywmg-amibxesrsfxsyuqduxstzhgqdlre",
    "url": "/tpdweb/configuratore-pet/configuratore-pet-form-app",
    "title": "Configuratore Pet Form App",
    "description": "",
    "layout": "layout-templates/hp/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "true",
    "profile": "profiles/profile_unipolsai_header_footer.json",
    "lastModified": "2022-11-29"
  },
  {
    "id": "gxsywmg-xtowactqrcebnejfewvdgjgnnplh",
    "url": "/tpdweb/fantacalcio-premium",
    "title": "fantacalcio",
    "description": "",
    "layout": "layout-templates/PreventivatoreAutoForm/",
    "isBusinessChild": "false",
    "newArchitecture": "false",
    "siteMapEnable": "false",
    "profile": "profiles/profile_unipolsai.json",
    "lastModified": "2024-08-05"
  }
]