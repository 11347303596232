import { StoreUtils } from "../store/store.utils";
import { CommonMFE, Providers } from "@tpd-web-common-libs/nodejs-library";
import { commonHook } from "../remotes/hooks/common.hook";

export const NextRemoteHelper = {
  getClientSideRemoteProps(componentProps: CommonMFE.Types.TProps) {
    return this.getRemoteProps(componentProps, true);
  },

  getServerSideRemoteProps(componentProps: CommonMFE.Types.TProps) {
    return this.getRemoteProps(componentProps, false);
  },

  getRemoteProps(
    componentProps: CommonMFE.Types.TProps,
    isClientSideImport: boolean
  ) {
    const mapping = {
      'tpdHeaderRemote': 'tpdHeader',
      'tpdFooterRemote': 'tpdFooter'
    };
    const remoteService = Providers.RemoteService.getInstance();
    let remoteProps = {};
    if (mapping[componentProps.__mfeName__]) {
      componentProps.__mfeName__ = mapping[componentProps.__mfeName__];
    }
    if (
      "__mfeName__" in componentProps &&
      remoteService.isAvailableRemote(componentProps.__mfeName__)
    ) {
      const storeDepependencies =
        StoreUtils.getStoreDependenciesBy(isClientSideImport);
      remoteProps = {
        ...remoteProps,
        ...commonHook(storeDepependencies, isClientSideImport)(componentProps),
      };
      if ("hook" in remoteService.remotesMap[componentProps.__mfeName__]) {
        remoteProps = {
          ...remoteProps,
          ...remoteService.remotesMap[componentProps.__mfeName__].hook(
            storeDepependencies
          )(componentProps),
        };
      }
    } else {
      throw new Error(
        `MFE with MFE Name ${componentProps.__mfeName__} is not defined in REMOTES_MAP!`
      );
    }

    return remoteProps;
  },
};
